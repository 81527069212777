



import RegisterMobile from '../sections/register/template_01';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const RegisterPage = ()=>{
  
return (
  <div id="registerPage" class="page ">
  
  

  
  <RegisterMobile desktopTitleToggle={ true }
    mobileTitleToggle={ true } birthdayReminder={ true } />
  
  
  
  

  
  
  

  
</div>
)
}

export default RegisterPage;