import React, { useState, useEffect, useReducer, useRef } from "react";
// import { Stepper, Step } from "react-form-stepper";
import { useStoreState, APP_STORE_ACTION, useStoreDispatch } from '../../common/storeContext'
import { Container, Form, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useForm } from "react-hook-form";
import PageTitle from '../pageTitle/template_01';
import { useMiddletier } from "../../common/middletier";
import { Navigate } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import './template_01.css';

const FORGOT_PASSWORD_ACTION = {
  SET_EXIST_STATUS: 'SET_EXIST_STATUS',
  SET_SELECTED_VERIFY_TYPE: 'SET_SELECTED_VERIFY_TYPE',
  SET_VERIFY_ID: 'SET_VERIFY_ID',
  SET_VERIFY_CODE: 'SET_VERIFY_CODE',
  SET_CODE_VALIDATION_RESULT: 'SET_CODE_VALIDATION_RESULT',
  SET_COUNTRY_DAILING_CODE: 'SET_COUNTRY_DAILING_CODE',
  SET_SELECTED_COUNTRY_DAILING_CODE: 'SET_SELECTED_COUNTRY_DAILING_CODE'
}

const initialForgotPasswordData = {
  selectedVerifyType: 'SMS',
  verifyID_SMS: '',
  verifyCode_SMS: '',
  verifyID_EMAIL: '',
  verifyCode_EMAIL: '',
  codeValidationResult: true,
  usernameExist: true,
  phoneNumberExist: true,
  emailExist: false,
  phoneNumberError: false,
  emailError: false,
  countryDailingCode: [],
  selectedCountryDailingCode: ''
  // usernameExistAlert: '',
  // phoneNumberExistAlert: '',
  // emailExistAlert: '',

}

const forgotPasswordReducer = (state, action) => {
  switch (action.type) {

    case FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS: {
      const { field, value } = action.payload;
      return { ...state, [field]: value ?? false };
    }

    case FORGOT_PASSWORD_ACTION.SET_SELECTED_VERIFY_TYPE: {
      return { ...state, selectedVerifyType: action.payload };
    }

    case FORGOT_PASSWORD_ACTION.SET_VERIFY_ID: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case FORGOT_PASSWORD_ACTION.SET_VERIFY_CODE: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case FORGOT_PASSWORD_ACTION.SET_CODE_VALIDATION_RESULT: {
      return { ...state, codeValidationResult: action.payload };
    }

    case FORGOT_PASSWORD_ACTION.SET_COUNTRY_DAILING_CODE: {
      const countryDailingCode = action.payload
      return { ...state, countryDailingCode, selectedCountryDailingCode: countryDailingCode?.length > 0 ? countryDailingCode[0] : '60' };
    }

    case FORGOT_PASSWORD_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE: {
      return { ...state, selectedCountryDailingCode: action.payload };
    }


    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// VALIDATION RULES
const getValidationRules = (type, t, getValues, appState) => {
  switch (type) {
    case 'username':
      return {
        required: t('error_username_empty'),
        minLength: { value: 6, message: t('error_username_length') },
        maxLength: { value: 12, message: t('error_username_length') },
      };
    case 'email':
      return {
        required: appState.registration.emailRequired && t('error_email_empty'),
        pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: t('error_email_format') },
      };
    case 'phone_number':
      return {
        required: appState.registration.phoneRequired && t('error_phone_number_empty'),
        // pattern: { value: /^\(?(1[0-9]{1})\)?[-. ]?([0-9]{7,8})$/, message: t('error_phone_number_format') },
        pattern: { value: /^\d{8,}$/, message: t('error_phone_number_format') }
      };
    default:
      return {};
  }
};


// const One = (props) => {

//   const appState = useStoreState()
//   const { t } = useTranslation();
//   const { query, mutation } = useMiddletier()
//   const [forgotPasswordState, forgotPasswordDispatch] = useReducer(forgotPasswordReducer, initialForgotPasswordData)
//   const [verifyDialogShow, setverifyDialogShow] = useState(false);
//   const [disabledPhoneBtn, setdisabledPhoneBtn] = useState(true);
//   const [disabledEmailBtn, setdisabledEmailBtn] = useState(true);
//   const [disabledNextButton, setDisabledNextButton] = useState(true)
//   const [isLoading, setLoading] = useState(false);
//   const [alertShow, setAlertShow] = useState({});

//   const [timeExpires, setTimeExpires] = useState(+`${appState.registration.otpPinExpiresIn}`);
//   const [send, setSend] = useState(false);
//   const minutes = Math.floor(timeExpires / 60000);
//   const seconds = Math.floor((timeExpires % 60000) / 1000);

//   const {
//     register: forgot_password,
//     getValues,
//     setValue,
//     formState: { errors, isValid, dirtyFields },
//     handleSubmit,
//   } = useForm({
//     mode: "onChange",
//   });


//   const {
//     register: verifyCode,
//     getValues: getCodeValue,
//     formState: { errors: errorsCode },
//     handleSubmit: handleSubmitCode,
//   } = useForm({
//     mode: "onChange",
//   });

//   // WHEN FOCUS USERNAME INPUT
//   const onFocusUsername = () => {
//     forgotPasswordDispatch({
//       type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//       payload: { field: 'usernameExist', value: true },
//     });
//   }
//   // WHEN FOCUS VERIFICATION CODE INPUT
//   const onFocusVerificationCode = () => {
//     forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_CODE_VALIDATION_RESULT, payload: true })
//   }

//   // WHEN FOCUS PHONE NUMBER INPUT
//   const onFocusPhoneNumber = () => {
//     forgotPasswordDispatch({
//       type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//       payload: { field: 'phoneNumberExist', value: true },
//     });
//   }

//   // WHEN FOCUS EMAIL INPUT
//   const onFocusEmail = () => {
//     forgotPasswordDispatch({
//       type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//       payload: { field: 'emailExist', value: true },
//     });
//   }

//   // CHECK IF USERNAME / EMAIL / PHONENUMBER (CONTACT) EXIST
//   const checkExist = (type) => {
//     console.log('type: ', type)
//     const { email, phone_number, currency, username } = getValues();
//     const method = (type === 'EMAIL' ? 'checkEmail' : (type === 'SMS' ? 'checkPhoneNumber' : 'checkUsername'))
//     const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency

//     query({
//       method,
//       params: [
//         { code: type === 'EMAIL' ? 'email' : (type === 'SMS' ? 'phone_number' : 'username'), graphqlType: 'String', required: true, value: type === 'EMAIL' ? email : (type === 'SMS' ? `${checkCurrency}${phone_number}` : username) },
//       ],
//       attributes: []

//     }).then(({ data }) => {
//       // console.log('data', data[method])
//       if (type === 'EMAIL') {
//         setdisabledEmailBtn(false);
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'emailExist', value: false },
//         });
//       }
//       else if (type === 'USERNAME') {
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'usernameExist', value: false },
//         });
//       }
//       else {
//         setdisabledPhoneBtn(false);
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'phoneNumberExist', value: false },
//         });
//       }

//     }).catch((error) => {
//       console.log('error: ', error)
//       if (type === 'EMAIL') {
//         setdisabledEmailBtn(true);
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'emailExist', value: true },
//         });
//       }
//       else if (type === 'USERNAME') {
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'usernameExist', value: true },
//         });
//       } else {
//         setdisabledPhoneBtn(true);
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
//           payload: { field: 'phoneNumberExist', value: true },
//         });
//       }
//     })

//   }



//   const onClickVerify = (verifyType) => {
//     const { phone_number, email, currency } = getValues();
//     const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency

//     const method = 'requestOTP'
//     const value_destination = verifyType === 'SMS' ? `${checkCurrency}${phone_number}` : email
//     console.log('channel', verifyType)
//     console.log('destination', value_destination)
//     mutation([{
//       method,
//       params: [
//         { code: 'channel', graphqlType: 'String', required: false, value: verifyType },
//         { code: 'destination', graphqlType: 'String', required: true, value: value_destination },
//         { code: 'type', graphqlType: 'String', required: true, value: "MEMBER_FORGET_PASSWORD" }
//       ],
//       attributes: []

//     }])
//       .then(({ data }) => {
//         console.log('requestOTP', data[method])
//         // verifyType === 'SMS' ? setVerifyID_SMS(data[method].id) : setVerifyID_EMAIL(data[method].id)
//         forgotPasswordDispatch({
//           type: FORGOT_PASSWORD_ACTION.SET_VERIFY_ID,
//           payload: { field: `${verifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: data[method].id },
//         });

//         setSend(true)
//         setTimeExpires(+`${appState.registration.otpPinExpiresIn}`)

//       })
//       .catch((error) => {
//         console.log('error: ', error)
//       })
//     forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_SELECTED_VERIFY_TYPE, payload: verifyType })
//     // setVerifyType(verifyType)
//     setverifyDialogShow(true)
//   }

//   const onClickVerifyConfirm = () => {

//     const { code } = getCodeValue();

//     const method = 'verifyOTP'
//     const valueId = forgotPasswordState.selectedVerifyType === 'SMS' ? forgotPasswordState.verifyID_SMS : forgotPasswordState.verifyID_EMAIL
//     console.log('method', method)
//     console.log('valueId', code)

//     mutation([{
//       method,
//       params: [
//         { code: 'id', graphqlType: 'String', required: true, value: valueId },
//         { code: 'pin', graphqlType: 'String', required: true, value: code },
//       ],
//       attributes: []

//     }])
//       .then(({ data }) => {
//         console.log('data', data[method])
//         // setCheckCode(data[method])
//         forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_CODE_VALIDATION_RESULT, payload: data[method] })

//         // data[method] && setverifyDialogShow(false)
//         // data[method] && (verifyType === 'SMS' ? setVerifyCode_SMS(code) : setVerifyCode_EMAIL(code))

//         if (data[method]) {
//           setverifyDialogShow(false)
//           forgotPasswordDispatch({
//             type: FORGOT_PASSWORD_ACTION.SET_VERIFY_CODE,
//             payload: { field: `${forgotPasswordState.selectedVerifyType === 'SMS' ? 'verifyCode_SMS' : 'verifyCode_EMAIL'}`, value: code },
//           });

//         }

//       })
//       .catch((error) => {
//         console.log('error: ', error)
//       })

//   }


//   useEffect(() => {

//     const timer = setInterval(() => {
//       setTimeExpires(timeExpires - 1000);
//     }, 1000);

//     if (timeExpires === 0) {
//       clearInterval(timer);
//       setSend(false)
//     }

//     return () => {
//       clearInterval(timer);
//     };

//   }, [timeExpires, send]);

//   const isDesktopOrLaptop = useMediaQuery({
//     query: '(min-width: 992px)'
//   })

//   // const onSubmit = () => {
//   //   setValue('otpPhoneId', forgotPasswordState.verifyID_SMS)
//   //   setValue('otpEmailId', forgotPasswordState.verifyID_EMAIL)
//   //   setValue('verifyType', forgotPasswordState.selectedVerifyType)
//   //   const values = getValues();
//   //   props.userCallback(values);
//   //   props.nextStep();
//   // };

//   const onSubmit = () => {
//     setLoading(true)
//     const values = getValues();
//     const countryDailingCode = appState.registration.countryDailingCode[0] ?? '60'
//     const method = 'resetPasswordByMember'
//     props.userCallback(values);

//     let params = [
//       { code: 'username', graphqlType: 'String', required: true, value: values.username },
//       { code: 'newPassword', graphqlType: 'String', required: true, value: values.password },
//     ]

//     if (forgotPasswordState.verifyID_SMS !== '') {
//       params.push({ code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${values.phone_number}` })
//       params.push({ code: 'otpPhoneId', graphqlType: 'String', required: false, value: forgotPasswordState.verifyID_SMS })
//     }

//     if (forgotPasswordState.verifyID_EMAIL !== '') {
//       params.push({ code: 'email', graphqlType: 'String', required: false, value: values.email })
//       params.push({ code: 'otpEmaileId', graphqlType: 'String', required: false, value: forgotPasswordState.verifyID_EMAIL })
//     }



//     mutation([{
//       method,
//       params,
//       attributes: []
//     }])
//       .then(({ data }) => {
//         setLoading(false)
//         console.log('resetPasswordByMember', data[method])
//         setAlertShow({ show: true, message: 'Change Password Successful', type: 'success' });
//       })
//       .catch((error) => {
//         setLoading(false)
//         console.log('error: ', error)
//       })
//   };


//   useEffect(() => {
//     if (!isValid || ((forgotPasswordState.verifyID_SMS === '' && forgotPasswordState.verifyCode_SMS === '') && (forgotPasswordState.verifyID_EMAIL === '' && forgotPasswordState.verifyCode_EMAIL === '')) || !forgotPasswordState.phoneNumberExist || !forgotPasswordState.emailExist || !forgotPasswordState.usernameExist) {
//       setDisabledNextButton(true)
//     } else {
//       setDisabledNextButton(false)
//     }

//   }, [forgotPasswordState.verifyID_SMS, forgotPasswordState.verifyID_EMAIL, isValid, forgotPasswordState.phoneNumberExist, forgotPasswordState.emailExist, forgotPasswordState.usernameExist, forgotPasswordState.verifyCode_SMS, forgotPasswordState.verifyCode_EMAIL]);


//   return (
//     <div>


//     </div>
//   );
// };

// const Two = (props) => {
//   const appState = useStoreState()
//   const { mutation } = useMiddletier()
//   const [isLoading, setLoading] = useState(false);
//   const [alertShow, setAlertShow] = useState({});
//   const {
//     register: forgot_password,
//     getValues,
//     formState: { errors, isValid },
//     handleSubmit,
//   } = useForm({
//     mode: "onBlur",
//   });

//   const { t } = useTranslation();


//   const isDesktopOrLaptop = useMediaQuery({
//     query: '(min-width: 992px)'
//   })
//   const handleBack = () => {
//     props.previousStep();
//   };

//   const onSubmit = () => {
//     setLoading(true)
//     const values = getValues();
//     const countryDailingCode = appState.registration.countryDailingCode[0] ?? '60'
//     const method = 'resetPasswordByMember'
//     props.userCallback(values);

//     let params = [
//       { code: 'username', graphqlType: 'String', required: true, value: props.user.username },
//       { code: 'newPassword', graphqlType: 'String', required: true, value: values.password },
//     ]

//     if (props.user.otpPhoneId !== '') {
//       params.push({ code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${props.user.phone_number}` })
//       params.push({ code: 'otpPhoneId', graphqlType: 'String', required: false, value: props.user.otpPhoneId })
//     }

//     if (props.user.otpEmailId !== '') {
//       params.push({ code: 'email', graphqlType: 'String', required: false, value: props.user.email })
//       params.push({ code: 'otpEmaileId', graphqlType: 'String', required: false, value: props.user.otpEmailId })
//     }



//     mutation([{
//       method,
//       params,
//       attributes: []
//     }])
//       .then(({ data }) => {
//         setLoading(false)
//         console.log('resetPasswordByMember', data[method])
//         setAlertShow({ show: true, message: 'Change Password Successful', type: 'success' });
//       })
//       .catch((error) => {
//         setLoading(false)
//         console.log('error: ', error)
//       })
//   };
//   return (
//     <div>

//       <Form className="forgotPassword_form_step2" onSubmit={handleSubmit(onSubmit)}>
//         <Form.Group className="form_field" >
//           <Form.Label>{t('password')}<div className="form_required">*</div></Form.Label>
//           <div className="form_field_input">
//             <Form.Control
//               type="text"
//               name="password"
//               placeholder={t('password')}
//               className="input_password"
//               aria-label="Password"
//               autoComplete="off"
//               onKeyDown={(event) => {
//                 if (event.key === ' ') {
//                   event.preventDefault();
//                 }
//               }}
//               onInput={(event) => {
//                 if (event.target.value.includes(' ')) {
//                   event.target.value = event.target.value.replace(/ /g, '');
//                 }
//               }}
//               {...forgot_password("password", { required: t('forgotPassword_error_password_empty'), minLength: { value: 6, message: t('forgotPassword_error_password_length') } })}
//             />
//             <p className="forgotPassword_error_msg">{errors.password?.message}</p>
//           </div>
//         </Form.Group>
//         <Form.Group className="form_field" >
//           <Form.Label>{t('confirm_password')}<div className="form_required">*</div></Form.Label>
//           <div className="form_field_input">
//             <Form.Control
//               type="text"
//               name="confirm_password"
//               placeholder={t('confirm_password')}
//               className="input_confirm_password"
//               aria-label="Confirm Password"
//               autoComplete="off"
//               onKeyDown={(event) => {
//                 if (event.key === ' ') {
//                   event.preventDefault();
//                 }
//               }}
//               onInput={(event) => {
//                 if (event.target.value.includes(' ')) {
//                   event.target.value = event.target.value.replace(/ /g, '');
//                 }
//               }}
//               {...forgot_password("confirm_password", {
//                 required: t('forgotPassword_error_confirm_password_empty'), minLength: { value: 6, message: t('forgotPassword_error_confirm_password_length') }, validate: (value) => {
//                   const { password } = getValues();
//                   return password === value || t('forgotPassword_error_confirm_password_matchPassword');
//                 }
//               })}
//             />
//             <p className="forgotPassword_error_msg">{errors.confirm_password?.message}</p>
//           </div>
//         </Form.Group>


//         {!isDesktopOrLaptop && <div className="forgotPassword_form_reminder">
//           <div className="forgotPassword_form_reminder_title">
//             {t('reminder')}
//           </div>
//           <div className="forgotPassword_form_reminder_content">
//             {props.user.verifyType === 'SMS' ? t('forgotPassword_reminder_phone_number') : t('forgotPassword_reminder_email')}
//           </div>
//         </div>}
//         <Form.Group className="form_field" >
//           <Form.Label></Form.Label>
//           <div className="form_field_input input_submit_wrap">
//             <button onClick={handleBack} type='button' className="font_button color_button forgotPassword_btnBack">{t('back')}</button>
//             <input type='submit' className="font_button color_button forgotPassword_btnSubmit" value={t('submit')} disabled={!isValid} />
//           </div>
//         </Form.Group>

//       </Form>
//       <hr className="forgotPassword_form_line" />

//       {isDesktopOrLaptop && <div className="forgotPassword_form_reminder">
//         <div className="forgotPassword_form_reminder_title">
//           {t('reminder')}
//         </div>
//         <div className="forgotPassword_form_reminder_content">
//           {props.user.verifyType === 'SMS' ? t('forgotPassword_reminder_phone_number') : t('forgotPassword_reminder_email')}
//         </div>
//       </div>}
//       <Alert show={alertShow.show} onHide={() => setAlertShow({ ...alertShow, show: false })} message={alertShow.message} type={alertShow.type} title={alertShow.title} />
//       {isLoading && (<Loading />)}
//     </div >
//   );

// };

const Forgotpassword = (props) => {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const { desktopTitleToggle, mobileTitleToggle } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const { t } = useTranslation();
  const { query, mutation } = useMiddletier()
  const [forgotPasswordState, forgotPasswordDispatch] = useReducer(forgotPasswordReducer, initialForgotPasswordData)
  const [verifyDialogShow, setverifyDialogShow] = useState(false);
  const [disabledPhoneBtn, setdisabledPhoneBtn] = useState(false);
  const [disabledEmailBtn, setdisabledEmailBtn] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [disabledPasswordInput, setDisabledPasswordInput] = useState(true)
  const [isLoading, setLoading] = useState(false);
  const [isLoadingOTP, setLoadingOTP] = useState(false);

  const [alertShow, setAlertShow] = useState({});
  const [visitorId, SetVisitorId] = useState()

  const [timeExpires, setTimeExpires] = useState(+`${appState.registration.otpPinExpiresIn}`);
  const [send, setSend] = useState(false);
  const minutes = Math.floor(timeExpires / 60000);
  const seconds = Math.floor((timeExpires % 60000) / 1000);
  const [toLink, setToLink] = useState('/')
  const isProcessing = useRef(false);

  const {
    register: forgot_password,
    getValues,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });


  const {
    register: verifyCode,
    getValues: getCodeValue,
    formState: { errors: errorsCode },
    handleSubmit: handleSubmitCode,
  } = useForm({
    mode: "onChange",
  });

  const onChangeCountryDailingCode = (code) => {
    forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE, payload: code })
  }

  // WHEN FOCUS USERNAME INPUT
  const onFocusUsername = () => {
    forgotPasswordDispatch({
      type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
      payload: { field: 'usernameExist', value: true },
    });
    // forgotPasswordDispatch({
    //   type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
    //   payload: { field: 'usernameExistAlert', value: '' },
    // });
  }

  // WHEN FOCUS VERIFICATION CODE INPUT
  const onFocusVerificationCode = () => {
    forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_CODE_VALIDATION_RESULT, payload: true })
  }

  // WHEN FOCUS PHONE NUMBER INPUT
  const onFocusPhoneNumber = () => {
    forgotPasswordDispatch({
      type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberExist', value: true },
    });
    forgotPasswordDispatch({
      type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberError', value: false },
    });
    setdisabledPhoneBtn(false)
  }

  // WHEN FOCUS EMAIL INPUT
  const onFocusEmail = () => {
    forgotPasswordDispatch({
      type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailExist', value: true },
    });
    forgotPasswordDispatch({
      type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailError', value: false },
    });
    setdisabledEmailBtn(false)
  }

  // CHECK IF USERNAME / EMAIL / PHONENUMBER (CONTACT) EXIST
  const checkExist = (type) => { 
    const { email, phone_number, username } = getValues();
    let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
    // console.log('checkFormatPhoneNumber', checkFormatPhoneNumber)
    const valueMap = {
      EMAIL: email,
      SMS: checkFormatPhoneNumber,
      USERNAME: username
    };
    const method = (type === 'EMAIL' ? 'checkEmail' : (type === 'SMS' ? 'checkPhoneNumber' : 'checkUsername'))
    // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
    const checkCurrency = forgotPasswordState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode
    if (valueMap[type]?.length > 0) {
      query({
        method,
        params: [
          { code: type === 'EMAIL' ? 'email' : (type === 'SMS' ? 'phone_number' : 'username'), graphqlType: 'String', required: true, value: type === 'EMAIL' ? email : (type === 'SMS' ? `${checkCurrency}${checkFormatPhoneNumber}` : username) },
        ],
        attributes: []

      }).then(({ data }) => {
        // console.log('data', data[method])
        if (type === 'EMAIL') {
          setdisabledEmailBtn(true);
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailExist', value: false },
          });
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailError', value: false },
          });

        }
        else if (type === 'USERNAME') {
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'usernameExist', value: false },
          });
          // forgotPasswordDispatch({
          //   type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
          //   payload: { field: 'usernameExistAlert', value: t('error_username_notExist') },
          // });
        }
        else {
          setdisabledPhoneBtn(true);
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberExist', value: false },
          });
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberError', value: false },
          });

        }

      }).catch((error) => {
        console.log('error: ', error)
        if (type === 'EMAIL') {
          setdisabledEmailBtn(false);
          if (error.toString().includes("Invalid")) {
            forgotPasswordDispatch({
              type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailError', value: true },
            });
          } else {
            forgotPasswordDispatch({
              type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailExist', value: true },
            });
            onClickVerify(type)
          }

        }
        else if (type === 'USERNAME') {
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
            payload: { field: 'usernameExist', value: true },
          });
        } else {
          setdisabledPhoneBtn(false);
          if (error.toString().includes("Invalid")) {
            forgotPasswordDispatch({
              type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberError', value: true },
            });
          } else {
            forgotPasswordDispatch({
              type: FORGOT_PASSWORD_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberExist', value: true },
            });
            onClickVerify(type)
          }

        }
      })
    }
  }



  const onClickVerify = (verifyType) => {
    if (isProcessing.current) {
      return;
    }
    isProcessing.current = true;
    setdisabledPhoneBtn(true)

    const { phone_number, email } = getValues();
    // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
    const checkCurrency = forgotPasswordState.selectedCountryDailingCode || appState.registration?.defaultCountryDailingCode
    if (forgotPasswordState.phoneNumberExist || forgotPasswordState.emailExist) {
      if (!send) {
        setLoadingOTP(true)
        const method = 'requestOTP'
        let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
        const value_destination = verifyType === 'SMS' ? `${checkCurrency}${checkFormatPhoneNumber}` : email
        // console.log('channel', verifyType)
        // console.log('destination', value_destination)
        mutation([{
          method,
          params: [
            { code: 'channel', graphqlType: 'String', required: false, value: verifyType },
            { code: 'destination', graphqlType: 'String', required: true, value: value_destination },
            { code: 'type', graphqlType: 'String', required: true, value: "MEMBER_FORGET_PASSWORD" },
            { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
          ],
          attributes: []

        }])
          .then(({ data }) => {
            isProcessing.current = false;
            setdisabledPhoneBtn(false)
            // console.log('requestOTP', data[method])
            setLoadingOTP(false)

            forgotPasswordDispatch({
              type: FORGOT_PASSWORD_ACTION.SET_VERIFY_ID,
              payload: { field: `${verifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: data[method].id },
            });

            setSend(true)
            setTimeExpires(+`${appState.registration.otpPinExpiresIn}`)
            forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_SELECTED_VERIFY_TYPE, payload: verifyType })
            setverifyDialogShow(true)

          })
          .catch((error) => {
            isProcessing.current = false;
            setdisabledPhoneBtn(false)
            setLoadingOTP(false)

            console.log('error: ', error)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
              console.log('GraphQL Errors:', error.graphQLErrors);
              error.graphQLErrors.forEach((err) => {
                console.log('Error message:', err.message);
                console.log('Error extensions code:', err.extensions?.code);
                if (err.extensions?.code === 'mutationE124') {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'Your device has been blocked for phone verification', typeAlert: 'error' }
                  });
                  setverifyDialogShow(false);
                }
              });
            } 
            
            if (error.toString().includes("suspend")) {
              setAlertShow({ show: true, message: error.message.toString(), type: 'error' });
            }
          })
      } else {
        isProcessing.current = false;
        setdisabledPhoneBtn(false)
        setverifyDialogShow(true)
      }
    }
  }

  const onClickVerifyConfirm = () => {

    const { code } = getCodeValue();

    const method = 'verifyOTP'
    const valueId = forgotPasswordState.selectedVerifyType === 'SMS' ? forgotPasswordState.verifyID_SMS : forgotPasswordState.verifyID_EMAIL
    // console.log('method', method)
    // console.log('valueId', code)

    mutation([{
      method,
      params: [
        { code: 'id', graphqlType: 'String', required: true, value: valueId },
        { code: 'pin', graphqlType: 'String', required: true, value: code },
      ],
      attributes: []

    }])
      .then(({ data }) => {
        forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_CODE_VALIDATION_RESULT, payload: data[method] })

        if (data[method]) {
          setDisabledPasswordInput(false)
          setverifyDialogShow(false)
          forgotPasswordDispatch({
            type: FORGOT_PASSWORD_ACTION.SET_VERIFY_CODE,
            payload: { field: `${forgotPasswordState.selectedVerifyType === 'SMS' ? 'verifyCode_SMS' : 'verifyCode_EMAIL'}`, value: code },
          });

        }

      })
      .catch((error) => {
        console.log('error: ', error)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      })

  }


  useEffect(() => {

    const timer = setInterval(() => {
      setTimeExpires(timeExpires - 1000);
    }, 1000);

    if (timeExpires === 0) {
      clearInterval(timer);
      setSend(false)
      forgotPasswordDispatch({
        type: FORGOT_PASSWORD_ACTION.SET_VERIFY_ID,
        payload: { field: `${forgotPasswordState.selectedVerifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: '' },
      });
    }

    return () => {
      clearInterval(timer);
    };

  }, [timeExpires, send, forgotPasswordState.selectedVerifyType]);


  useEffect(() => {
    const getDefaultCountryDailingCode = appState.registration?.defaultCountryDailingCode ?? '60'
    if (appState.registration?.countryDailingCode?.includes(getDefaultCountryDailingCode)) {
      appState.registration?.countryDailingCode?.splice(appState.registration?.countryDailingCode.indexOf(getDefaultCountryDailingCode), 1);
    }

    appState.registration?.countryDailingCode?.unshift(getDefaultCountryDailingCode);

    forgotPasswordDispatch({ type: FORGOT_PASSWORD_ACTION.SET_COUNTRY_DAILING_CODE, payload: appState.registration?.countryDailingCode })


  }, [appState.registration?.countryDailingCode, appState.registration?.defaultCountryDailingCode]);

  const onHideAlert = () => {
    if (alertShow.onHide) {
      alertShow.onHide()
    }

    setAlertShow({ ...alertShow, show: false })
  }

  const onSubmit = () => {
    setLoading(true)
    const values = getValues();
    // const countryDailingCode = appState.registration.countryDailingCode[0] ?? '60'
    const countryDailingCode = forgotPasswordState.selectedCountryDailingCode || appState.registration?.defaultCountryDailingCode
    const method = 'resetPasswordByMember'


    let params = [
      { code: 'username', graphqlType: 'String', required: true, value: values.username },
      { code: 'newPassword', graphqlType: 'String', required: true, value: values.password },
    ]

    if (forgotPasswordState.verifyID_SMS !== '') {
      params.push({ code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${values.phone_number}` })
      params.push({ code: 'otpPhoneId', graphqlType: 'String', required: false, value: forgotPasswordState.verifyID_SMS })
    }

    if (forgotPasswordState.verifyID_EMAIL !== '') {
      params.push({ code: 'email', graphqlType: 'String', required: false, value: values.email })
      params.push({ code: 'otpEmaileId', graphqlType: 'String', required: false, value: forgotPasswordState.verifyID_EMAIL })
    }



    mutation([{
      method,
      params,
      attributes: []
    }])
      .then(({ data }) => {
        setLoading(false)
        setAlertShow({
          show: true, message: 'Change Password Successful', type: 'success', onHide: () => {
            setLoading(true)
            mutation([{
              method: 'loginByMember',
              params: [
                { code: 'username', graphqlType: 'String', required: true, value: values?.username },
                { code: 'password', graphqlType: 'String', required: true, value: values?.password },
                { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
              ],
              attributes: []
            }])
              .then(({ data }) => {
                setLoading(false)
                appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data['loginByMember'] } })
                setToLink('/')
              })
              .catch((error) => {
                console.log('error: ', error)
              })
          }
        });


      })
      .catch((error) => {
        setLoading(false)
        console.log('error: ', error)
        setAlertShow({
          show: true, message: error.message.toString(), type: 'error'
        });
      })
  };

  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])



  useEffect(() => {

    if (!isValid || ((forgotPasswordState.verifyID_SMS === '' && forgotPasswordState.verifyCode_SMS === '') && (forgotPasswordState.verifyID_EMAIL === '' && forgotPasswordState.verifyCode_EMAIL === '')) || (!forgotPasswordState.phoneNumberExist && !forgotPasswordState.emailExist) || !forgotPasswordState.usernameExist) {
      setDisabledNextButton(true)
    } else {
      setDisabledNextButton(false)
    }

  }, [forgotPasswordState.verifyID_SMS, forgotPasswordState.verifyID_EMAIL, isValid, forgotPasswordState.phoneNumberExist, forgotPasswordState.emailExist, forgotPasswordState.usernameExist, forgotPasswordState.verifyCode_SMS, forgotPasswordState.verifyCode_EMAIL]);


  return (
    <>
      {
        appState.loggedIn ?
          <Navigate to={toLink} /> :
          <section id="forgotpwd_01">
            <div className="forgotPassword_body">
              <PageTitle desktopToggle={false} mobileToggle={mobileTitleToggle} title={'forgot_password'} id={'forgotPassword_title_m'} />
              <Container className={isDesktopOrLaptop && "forgotPassword_container"}>
                <div className="forgotPassword_container_wrap">
                  <div className="forgotPassword_form">
                    <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={false} title={'forgot_password'} id={'forgotPassword_title_d'} />
                    <Form className="forgotPassword_form_step1" onSubmit={handleSubmit(onSubmit)}>

                      {/* <div class="verifyType_Tab">
                            <div className={`verify_type ${verifyType === 'SMS' && 'active'}`} onClick={() => { setVerifyType("SMS") }}>{t('phone_number')}</div>
                            <div className={`verify_type ${verifyType === 'EMAIL' && 'active'}`} onClick={() => { setVerifyType("EMAIL") }}>{t('email')}</div>
                            <div className={`verify_type_line`}></div>
                          </div> */}

                      <Form.Group className="form_field form_field_username" >
                        <Form.Label>{t('username')} <div className="form_required">*</div></Form.Label>
                        <div className="form_field_input">
                          <Form.Control
                            type="text"
                            name="username"
                            placeholder={t('username')}
                            className="input_username"
                            aria-label="Username"
                            onFocus={onFocusUsername}
                            onBlurCapture={() => checkExist('USERNAME')}
                            autoComplete="off"
                            onKeyDown={(event) => {
                              if (event.key === ' ') {
                                event.preventDefault();
                              }
                            }}
                            onInput={(event) => {
                              if (event.target.value.includes(' ')) {
                                event.target.value = event.target.value.replace(/ /g, '');
                              }
                              event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                            }}

                            {...forgot_password("username", getValidationRules('username', t))}
                          // {...forgot_password("username", {
                          //   required: t('error_username_empty'), minLength: { value: 6, message: t('error_username_length') }, maxLength: { value: 12, message: t('error_username_length') }
                          // })}
                          />
                          <p className="forgotPassword_error_msg">{errors.username?.message}</p>
                          {/* {(forgotPasswordState.usernameExistAlert !== '' && !errors.username) && (<p className="forgotPassword_error_msg">{forgotPasswordState.usernameExistAlert}</p>)} */}
                          {(!forgotPasswordState.usernameExist && !errors.username) && <p className="forgotPassword_error_msg">{t('error_username_notExist')}</p>}
                        </div>
                      </Form.Group>
                      <Form.Group className="form_field form_field_username" >
                        <Form.Label>{t(forgotPasswordState.selectedVerifyType === 'SMS' ? 'phone_number' : 'email')} <div className="form_required">*</div></Form.Label>
                        {forgotPasswordState.selectedVerifyType === 'SMS' ?

                          <div className="form_field_input">
                            <div className="w-100 d-flex align-items-center">
                              <Form.Select aria-label="select_currency" className="select_currency w-25 me-1" disabled={forgotPasswordState.verifyCode_SMS} {...forgot_password("currency")} value={forgotPasswordState.selectedCountryDailingCode} onChange={(evt) => { onChangeCountryDailingCode(evt.target.value) }}>
                                {(appState.registration.countryDailingCode ?? []).map(function (item, index) {
                                  return (
                                    <option value={item} key={index} selected={index === 0 && true}>+{item}</option>
                                  )
                                })}
                              </Form.Select>

                              <Form.Control
                                type="text"
                                name="phone_number"
                                placeholder={t('phone_number')}
                                className="input_phone_number w-75"
                                aria-label="phone_number"
                                autoComplete="off"
                                disabled={forgotPasswordState.verifyCode_SMS}
                                // onBlurCapture={() => checkExist('SMS')}
                                onFocus={onFocusPhoneNumber}
                                onKeyDown={(event) => {
                                  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
                                  if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                    event.preventDefault();
                                  }

                                }}
                                onInput={(event) => {
                                  // event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                  const input = event.target.value.replace(/[^0-9]/g, '');
                                  const checkCurrency = forgotPasswordState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode
                
                                  if (checkCurrency === '60') {
                                    if (input.startsWith('011')) {
                                      event.target.value = input.slice(0, 11);
                                    } else if (input.startsWith('11')) {
                                      event.target.value = input.slice(0, 10);
                                    } else if (input.startsWith('0')) {
                                      event.target.value = input.slice(0, 10);
                                    } else {
                                      event.target.value = input.slice(0, 9);
                                    }
                                  } else {
                                    event.target.value = input;
                                  }
                                }}

                                {...forgot_password("phone_number", getValidationRules('phone_number', t, getValues, appState))}
                              />
                              {!forgotPasswordState.verifyCode_SMS && <button type="button" className={`color_button forgotPassword_btnVerify ${(forgotPasswordState.verifyID_SMS !== '' || isLoadingOTP) && ('btnAfterVerify')}`} disabled={!dirtyFields.phone_number ? true : (errors.phone_number && dirtyFields.phone_number ? true : disabledPhoneBtn)} onClick={() => { checkExist("SMS") }}>{t('verify')}</button>}
                            </div>
                            <p className="forgotPassword_error_msg">{errors.phone_number?.message}</p>
                            {/* {
                              (() => {
                                const { phone_number } = getValues();
                                if (!forgotPasswordState.phoneNumberExist && phone_number.length > 0 && !errors.phone_number) {
                                  return (
                                    <p className="forgotPassword_error_msg">
                                      Your phone number has not been registered.
                                    </p>
                                  );
                                }
                                return null;
                              })()
                            } */}
                            {/* {(forgotPasswordState.phoneNumberExistAlert !== '' && !errors.phone_number) && (<p className="forgotPassword_error_msg">{forgotPasswordState.phoneNumberExistAlert}</p>)} */}
                            {(!forgotPasswordState.phoneNumberExist && !errors.phone_number) && <p className="forgotPassword_error_msg">{t('error_phone_number_notExist')}</p>}
                            {(forgotPasswordState.phoneNumberError && !errors.phone_number) && <p className="forgotPassword_error_msg">{t('error_phone_number_format')}</p>}
                          </div> :
                          <div className="form_field_input">
                            <div className="w-100 d-flex align-items-center">
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder={t('email')}
                                className="input_email"
                                aria-label="email"
                                autoComplete="off"
                                onFocus={onFocusEmail}
                                onKeyDown={(event) => {
                                  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', '@', '.', '-', '_', 'Shift'];
                                  if (!/[a-zA-Z0-9.@_-]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onInput={(event) => {
                                  event.target.value = event.target.value.replace(/[^a-zA-Z0-9.@_-]/g, '');
                                }}
                                // onBlurCapture={() => checkExist('EMAIL')}
                                // {...forgot_password("email", {
                                //   required: appState.registration.emailRequired && t('forgotPassword_error_email_empty'), pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: t('forgotPassword_error_email_format') },
                                // })}
                                {...forgot_password("email", getValidationRules('email', t, getValues, appState))}
                              />

                              {!forgotPasswordState.verifyCode_EMAIL && <button type="button" className={`color_button forgotPassword_btnVerify `} disabled={!dirtyFields.email ? true : (errors.email && dirtyFields.email ? true : disabledEmailBtn)} onClick={() => { onClickVerify("EMAIL") }}>{t('verify')}</button>}
                            </div>
                            <p className="forgotPassowrd_error_msg">{errors.email?.message}</p>
                            {/* {(!forgotPasswordState.emailExist) && <p className="forgotPassword_error_msg">Your email has not been registered.</p>} */}
                            {/* {
                              (() => {
                                const { email } = getValues();
                                if (!forgotPasswordState.emailExist && email.length > 0 && !errors.email) {
                                  return (
                                    <p className="forgotPassword_error_msg">
                                      Your email has not been registered.
                                    </p>
                                  );
                                }
                                return null;
                              })()
                            } */}
                            {/* {(forgotPasswordState.emailExistAlert !== '' && !errors.email) && (<p className="forgotPassword_error_msg">{forgotPasswordState.emailExistAlert}</p>)} */}
                            {(!forgotPasswordState.emailExist && !errors.email) && <p className="forgotPassword_error_msg">{t('error_email_notExist')}</p>}
                            {(forgotPasswordState.emailError && !errors.email) && <p className="forgotPassword_error_msg">{t('error_email_format')}</p>}
                          </div>}
                      </Form.Group>
                      <Form.Group className="form_field" >
                        <Form.Label>{t('password')}<div className="form_required">*</div></Form.Label>
                        <div className="form_field_input">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder={t('password')}
                            className="input_password"
                            aria-label="Password"
                            autoComplete="off"
                            disabled={disabledPasswordInput}
                            onKeyDown={(event) => {
                              if (event.key === ' ') {
                                event.preventDefault();
                              }
                            }}
                            onInput={(event) => {
                              if (event.target.value.includes(' ')) {
                                event.target.value = event.target.value.replace(/ /g, '');
                              }
                            }}
                            {...forgot_password("password", { required: t('error_password_empty'), minLength: { value: 6, message: t('error_password_length') } })}
                          />
                          <p className="forgotPassword_error_msg">{errors.password?.message}</p>
                        </div>
                      </Form.Group>
                      <Form.Group className="form_field" >
                        <Form.Label>{t('confirm_password')}<div className="form_required">*</div></Form.Label>
                        <div className="form_field_input">
                          <Form.Control
                            type="password"
                            name="confirm_password"
                            placeholder={t('confirm_password')}
                            className="input_confirm_password"
                            aria-label="Confirm Password"
                            autoComplete="off"
                            disabled={disabledPasswordInput}
                            onKeyDown={(event) => {
                              if (event.key === ' ') {
                                event.preventDefault();
                              }
                            }}
                            onInput={(event) => {
                              if (event.target.value.includes(' ')) {
                                event.target.value = event.target.value.replace(/ /g, '');
                              }
                            }}
                            {...forgot_password("confirm_password", {
                              required: t('error_confirm_password_empty'), minLength: { value: 6, message: t('error_confirm_password_length') }, validate: (value) => {
                                const { password } = getValues();
                                return password === value || t('error_confirm_password_matchPassword');
                              }
                            })}
                          />
                          <p className="forgotPassword_error_msg">{errors.confirm_password?.message}</p>
                        </div>
                      </Form.Group>
                      {!isDesktopOrLaptop && <div className="forgotPassword_form_reminder">
                        <div className="forgotPassword_form_reminder_title">
                          {t('reminder')}
                        </div>
                        <div className="forgotPassword_form_reminder_content">
                          {forgotPasswordState.selectedVerifyType === 'SMS' ? t('forgotPassword_reminder_phone_number') : t('forgotPassword_reminder_email')}
                        </div>
                      </div>}
                      <Form.Group className="form_field" >
                        <Form.Label></Form.Label>
                        <div className="form_field_input input_submit_wrap">
                          <input type='submit' className="font_button color_button forgotPassword_btnNext" value={t('submit')} disabled={disabledNextButton} />
                        </div>
                      </Form.Group>
                    </Form>
                    <Modal
                      show={verifyDialogShow}
                      onHide={() => setverifyDialogShow(false)}
                      backdrop="static"
                      keyboard={false}
                      id="forgotUser_verifyDialog"
                      className="verifyDialog"
                      centered
                    >
                      <Form onSubmit={handleSubmitCode(onClickVerifyConfirm)}>
                        <Modal.Header>
                          <Modal.Title>{t(forgotPasswordState.selectedVerifyType === 'SMS' ? "phone_number" : "email")} {t('verification_code')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Group className={`form_field`}>
                            <Form.Label> {t('verification_code')}</Form.Label>
                            <div className="form_field_input">
                              <div className="w-100 position-relative">
                                <Form.Control
                                  type="number"
                                  name="code"
                                  onFocus={onFocusVerificationCode}
                                  placeholder={t('verification_code')}
                                  className="input_code"
                                  aria-label="Code"
                                  autoComplete="off"
                                  {...verifyCode(`code`, {
                                    required: t('error_code_empty')
                                  })}
                                />
                                <div>
                                  {minutes}:{seconds < 10 ? '0' : ''}{seconds}
                                  <input type={'button'} value={t('resend')} className={`color_button btnSendCode`} onClick={() => onClickVerify(forgotPasswordState.selectedVerifyType)} disabled={send} />
                                </div>
                              </div>
                              <p className="error_msg">{errorsCode.code?.message} </p>
                              {forgotPasswordState.codeValidationResult === false && <p className="error_msg">{t('error_code_noMatch')} </p>}
                            </div>
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <div>
                            <button type={'button'} className={`color_button btnCancel`} onClick={() => setverifyDialogShow(false)} >{t('cancel')} </button>
                            <input type={'submit'} value={t('confirm')} className={`color_button`} /></div>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                    <hr className="forgotPassword_form_line" />
                    {isDesktopOrLaptop && <div className="forgotPassword_form_reminder">
                      <div className="forgotPassword_form_reminder_title">
                        {t('reminder')}
                      </div>
                      <div className="forgotPassword_form_reminder_content">
                        {forgotPasswordState.selectedVerifyType === 'SMS' ? t('forgotPassword_reminder_phone_number') : t('forgotPassword_reminder_email')}
                      </div>
                    </div>}
                    <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} />
                    {isLoading && (<Loading />)}
                  </div>
                </div>
              </Container>
            </div>
          </section >}
    </>
  );
};

export default Forgotpassword;
