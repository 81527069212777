import { Modal } from "react-bootstrap";
// import { TENANT_BUCKET } from '../../common/constants'
import { BsExclamationCircleFill } from 'react-icons/bs'
import './Alert.css';
import { TENANT_NAME, TENANT_BUCKET } from "../common/constants";
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../common/storeContext';


const Alert = (props) => {
    const { typeAlert, title, message, info } = props
    const { t } = useTranslation();
    const appState = useStoreState()

    // console.log('info', info)
    return (
        <Modal
            {...props}
            className={`alertMessage ${info}`}
            animation={info === 'point' ? true : false}
            centered
        >
            <div className="alertMessage_body">

                {info === 'point' ? <>
                    <Modal.Header className="alertMessage_header" closeButton>
                        <Modal.Title>
                            <span>{t('point_title')}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alertMessage_content">
                            <div className="point_subTitle">
                                <img src={`${TENANT_BUCKET}/icon/point_icon.png`} alt="header icon" />{TENANT_NAME} {t('point')}</div>
                            <div className="point_desciption"> {t('point_content')}</div>
                            <a href={appState?.mallUrl}>{appState?.mallUrl}</a>
                        </div>
                    </Modal.Body></> : <>
                    {title && (<Modal.Header className="alertMessage_header">{title}</Modal.Header>)}
                    <Modal.Body>
                        <div className="alertMessage_content">
                            {typeAlert === 'error' && <BsExclamationCircleFill className="me-2" />} <span dangerouslySetInnerHTML={{ __html: message }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`${!title && ('p-2')}`}>
                        <button type="button" onClick={props.onHide} className="font_button color_button alertMessage_btnClose">OK</button>
                    </Modal.Footer></>}
            </div>
        </Modal>

    );
}

export default Alert;