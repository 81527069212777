


import SECTION_CONTACTUS_TITLE from
'../dynamic_section/dynamic_2/template_01';






import ContactUs from '../sections/contact_us/template_01';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const ContactUsPage = ()=>{
  
return (
  <div id="contactUsPage" class="page ">
  
  

  
  < SECTION_CONTACTUS_TITLE desktopToggle={ true }
    mobileToggle={ false } label={ `contactUs_title` } id={ `contactUs_title` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  
  

  
  <ContactUs desktopTitleToggle={ true }
    mobileTitleToggle={ true } dropdownTitle={false} backgroundImgTitle={false}/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  

  
</div>
)
}

export default ContactUsPage;