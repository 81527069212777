import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Form, Modal, Container } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useForm } from "react-hook-form";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import { AiFillCopy, AiOutlineCheckCircle, AiOutlinePlus } from "react-icons/ai";
import VerificationSample from '../../../../dynamic_section/dynamic_2/default.js';
import { BiArrowBack } from "react-icons/bi";
import { TENANT_BUCKET,CHECK_NEW_TENANT } from '../../../../common/constants';

import { useNavigate, Link } from "react-router-dom";
import dayjs from 'dayjs';

const RealNameVerification = () => {
    const appState = useStoreState()

    const { t } = useTranslation();
    const [icFrontFiles, setICFrontFiles] = useState([]);
    const [icBackFiles, setICBackFiles] = useState([]);
    const [bankFiles, setBankFiles] = useState([]);
    const { query, mutation } = useMiddletier()
    const [isLoading, setLoading] = useState(false);
    const [selectedICFront, setSelectedICFront] = useState('');
    const [selectedICBack, setSelectedICBack] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const appDispatch = useStoreDispatch()
    const [refreshVerification, setRefreshVerification] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [showVerificationSample, setShowVerificationSample] = useState(false);


    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    const { getRootProps: getICFrontRootProps, getInputProps: getICFrontInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'application/pdf': []
        },
        onDrop: acceptedFiles => {
            setICFrontFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            setLoading(true)

            const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            const isAcceptedFileType = acceptedFileTypes.includes(acceptedFiles[0]?.type);
            const isLt8MB = acceptedFiles[0].size / 1024 / 1024 < 8;

            if (isAcceptedFileType&&isLt8MB) {
                let params = [
                    { code: 'folder', value: 'realname_verification', graphqlType: 'String', required: false },
                    { code: 'file', value: acceptedFiles[0], graphqlType: 'Upload', required: true }
                ]
              
                mutation([
                    {
                        method: 'singleUpload',
                        params,

                    }
                ])
                    .then(({ data }) => {
                        setLoading(false)
                        setSelectedICFront(data['singleUpload'].id)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error(error)
                        if (error?.networkError?.statusCode === 401) {
                            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

                        } else {
                            appDispatch({
                                type: APP_STORE_ACTION.SHOW_ALERT,
                                payload: { description: error.message.toString(), typeAlert: 'error' }
                            });
                        }
                    })
            } else {
                setLoading(false)
                if (!isAcceptedFileType) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Only JPEG and PNG image formats are supported. Please check your file type and try again.', typeAlert: 'error' }
                    });
                }
                if (!isLt8MB) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Image size must below 8MB', typeAlert: 'error' }
                    });
                }

            }

        }
    });

    const { getRootProps: getICBackRootProps, getInputProps: getICBackInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'application/pdf': []
        },
        onDrop: acceptedFiles => {
            setICBackFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            setLoading(true)

            const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            const isAcceptedFileType = acceptedFileTypes.includes(acceptedFiles[0]?.type);
            const isLt8MB = acceptedFiles[0].size / 1024 / 1024 < 8;

            if (isAcceptedFileType&&isLt8MB) {
                let params = [
                    { code: 'folder', value: 'realname_verification', graphqlType: 'String', required: false },
                    { code: 'file', value: acceptedFiles[0], graphqlType: 'Upload', required: true }
                ]
              
                mutation([
                    {
                        method: 'singleUpload',
                        params,

                    }
                ])
                    .then(({ data }) => {
                        setLoading(false)
                        setSelectedICBack(data['singleUpload'].id)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error(error)
                        if (error?.networkError?.statusCode === 401) {
                            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

                        } else {
                            appDispatch({
                                type: APP_STORE_ACTION.SHOW_ALERT,
                                payload: { description: error.message.toString(), typeAlert: 'error' }
                            });
                        }
                    })
            } else {
                setLoading(false)
                if (!isAcceptedFileType) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Only JPEG and PNG image formats are supported. Please check your file type and try again.', typeAlert: 'error' }
                    });
                }
                if (!isLt8MB) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Image size must below 8MB', typeAlert: 'error' }
                    });
                }

            }

        }
    });

    const { getRootProps: getBankRootProps, getInputProps: getBankInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'application/pdf': []
        },
        onDrop: acceptedFiles => {
            setBankFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            setLoading(true)

            const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            const isAcceptedFileType = acceptedFileTypes.includes(acceptedFiles[0]?.type);
            const isLt8MB = acceptedFiles[0].size / 1024 / 1024 < 8;

            if (isAcceptedFileType&&isLt8MB) {
                let params = [
                    { code: 'folder', value: 'realname_verification', graphqlType: 'String', required: false },
                    { code: 'file', value: acceptedFiles[0], graphqlType: 'Upload', required: true }
                ]
              
                mutation([
                    {
                        method: 'singleUpload',
                        params,

                    }
                ])
                    .then(({ data }) => {
                        setLoading(false)
                        setSelectedBank(data['singleUpload'].id)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error(error)
                        if (error?.networkError?.statusCode === 401) {
                            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

                        } else {
                            appDispatch({
                                type: APP_STORE_ACTION.SHOW_ALERT,
                                payload: { description: error.message.toString(), typeAlert: 'error' }
                            });
                        }
                    })
            } else {
                setLoading(false)
                if (!isAcceptedFileType) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Only JPEG and PNG image formats are supported. Please check your file type and try again.', typeAlert: 'error' }
                    });
                }
                if (!isLt8MB) {
                    return appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'Image size must below 8MB', typeAlert: 'error' }
                    });
                }

            }

        }
    });

    const onSubmitRealNameVerification = () => {
        setLoading(true)
        const method = 'updateVerificationByMember'


        let params = [
            { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
        ]

        if (selectedICFront !== '') {
            params.push({ code: 'icFrontImage', graphqlType: 'String', required: false, value: selectedICFront })
        }

        if (selectedICBack !== '') {
            params.push({ code: 'icBackImage', graphqlType: 'String', required: false, value: selectedICBack })
        }

        if (selectedBank !== '') {
            params.push({ code: 'bankVerificationImage', graphqlType: 'String', required: false, value: selectedBank })
        }



        mutation([{
            method,
            params,
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: {
                        description: `Update Verification Successful`, typeAlert: 'success', onHide: () => {

                        }
                    }
                });

                setRefreshVerification(true)

            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
    }

    useEffect(() => {
        if (typeof appState.user?.username !== 'undefined') {
            const method = 'member'
            query({
                method,
                params: [
                    { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.username },
                ],
                attributes: [['_ic_front_image', ['url']], ['_ic_back_image', ['url']], ['_bank_verification_image', ['url']]]

            }).then(({ data }) => {
                setUserInfo(data['member'])
            }).catch((error) => {

                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        }


    }, [appState?.user?.username, query, refreshVerification, appDispatch]);

    return (
        <>
            <div className="realNameVerification_container">
                <div className="realNameVerification_header">
                    <div className="realNameVerification_title subTitle">{isDesktopOrLaptop ? t('realName_verification') : t('identity_verification')}</div>
                    <button className="font_button color_button realNameVerification_viewSample_button" onClick={() => setShowVerificationSample(true)}>{t(`view_sample`)}</button>
                </div>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                        <label>{t('IC_frontVerification')}</label>
                        {(userInfo?._ic_front_image?.url) ?
                            <img src={userInfo?._ic_front_image?.url} alt={'ic front'} /> :
                            <section {...getICFrontRootProps({ className: 'uploadImageContainer' })}>
                                <input {...getICFrontInputProps()} />
                                <div className={`${icFrontFiles.length > 0 ? ('d-none') : 'beforeUploadImage'}`}>
                                    <div> <AiOutlinePlus /> <span>{t('upload')}</span></div>
                                    <div>{t('IC_frontVerification')}</div>
                                </div>
                                <div className="afterUploadImageContainer">
                                    <div className="afterUploadImage">
                                        {icFrontFiles?.map(function (item, index) {
                                            return (
                                                <img
                                                    src={item.preview}
                                                    key={item.name}
                                                    // Revoke data uri after image is loaded
                                                    onLoad={() => { URL.revokeObjectURL(item.preview) }}
                                                    alt=""
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        }

                    </ListGroup.Item>
                    <ListGroup.Item>
                        <label>{t('IC_backVerification')}</label>
                        {(userInfo?._ic_back_image?.url) ?
                            <img src={userInfo?._ic_back_image?.url} alt={'ic back'} /> :
                            <section {...getICBackRootProps({ className: 'uploadImageContainer' })}>
                                <input {...getICBackInputProps()} />
                                <div className={`${icBackFiles.length > 0 ? ('d-none') : 'beforeUploadImage'}`}>
                                    <div> <AiOutlinePlus /> <span>{t('upload')}</span></div>
                                    <div>{t('IC_backVerification')}</div>
                                </div>
                                <div className="afterUploadImageContainer">
                                    <div className="afterUploadImage">
                                        {icBackFiles?.map(function (item, index) {
                                            return (
                                                <img
                                                    src={item.preview}
                                                    key={item.name}
                                                    // Revoke data uri after image is loaded
                                                    onLoad={() => { URL.revokeObjectURL(item.preview) }}
                                                    alt=""
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>}
                    </ListGroup.Item>
                    {!isDesktopOrLaptop && <div className="realNameVerification_title subTitle">{t('bank_verification')}</div>}
                    <ListGroup.Item>
                        <label>{t('bankVerification')}</label>
                        {(userInfo?._bank_verification_image?.url) ?
                            <img src={userInfo?._bank_verification_image?.url} alt={'bank'} /> :
                            <section {...getBankRootProps({ className: 'uploadImageContainer' })}>
                                <input {...getBankInputProps()} />
                                <div className={`${bankFiles.length > 0 ? ('d-none') : 'beforeUploadImage'}`}>
                                    <div> <AiOutlinePlus /> <span>{t('upload')}</span></div>
                                    <div>{t('bankVerification')}</div>
                                </div>
                                <div className="afterUploadImageContainer">
                                    <div className="afterUploadImage">
                                        {bankFiles?.map(function (item, index) {
                                            return (
                                                <img
                                                    src={item.preview}
                                                    key={item.name}
                                                    // Revoke data uri after image is loaded
                                                    onLoad={() => { URL.revokeObjectURL(item.preview) }}
                                                    alt=""
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>}
                    </ListGroup.Item>
                    {/* <div className="reminder_container">
                        <div className="reminder_title">{t('reminder')}</div>
                        <div className="reminder_content">
                            {t(`realNameVerification_reminder`)}
                        </div>
                    </div> */}
                    {(!userInfo?._ic_front_image?.url || !userInfo?._ic_back_image?.url || !userInfo?._bank_verification_image?.url) && (
                        <input className="font_button color_button wallet_btnSubmit" type='submit' value={t('submit')} onClick={onSubmitRealNameVerification} />
                    )}
                </ListGroup>
            </div>
            {isLoading && (<Loading />)}
            {<Modal show={showVerificationSample} onHide={() => setShowVerificationSample(false)} centered id="verificationSampleModal">
                <Modal.Header closeButton>
                    <Modal.Title>{t('verification_sample')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VerificationSample desktopToggle={true}
                        mobileToggle={false} label={`Dynamic Section2`} id={`verification_sample`} supportLanguage={
                            true} titleToggle={false} titleIconToggle={
                                false} linkTo={``} addContainer={false} numberOfImage={1} titlePosition={'center'} />

                    <VerificationSample desktopToggle={false}
                        mobileToggle={true} label={`Dynamic Section2`} id={`verification_sample_m`} supportLanguage={
                            true} titleToggle={false} titleIconToggle={
                                false} linkTo={``} addContainer={false} numberOfImage={1} titlePosition={'center'} />
                </Modal.Body>
            </Modal>}
        </>

    )
}

const UserInfo = () => {
    const appState = useStoreState()
    const { query, mutation } = useMiddletier()
    const [userInfo, setUserInfo] = useState([]);
    const [dobEdit, setDobEdit] = useState(false);
    const [genderEdit, setGenderEdit] = useState(false);

    const [refreshBrithday, setRefreshBrithday] = useState(false);
    const [refreshGender, setRefreshGender] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const appDispatch = useStoreDispatch()
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedDob, setSelectedDob] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [selectedGender, setSelectedGender] = useState('');
    const [disabledGenderSubmit, setDisabledGenderSubmit] = useState(true);
    const [showUserInfo, setShowUserInfo] = useState(true);
    const [showRealNameVerification, setShowRealNameVerification] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })


    const {
        register: dob,
        getValues,
        formState: { isValid },
        handleSubmit,
    } = useForm({
        mode: "onChange",
    });

    const today = dayjs().format('YYYY-MM-DD');

    const onChangeDob = (date) => {
        if (dayjs(date).isAfter(today)) {
            // setDisabledSubmit(true)
            appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'date of birth cannot be after today.', typeAlert: 'success' }
            });
            return;
        } else {
            setSelectedDob(date)
        }

    }

    const onChangeGender = (data) => {
        setSelectedGender(data)
    }



    useEffect(() => {
        if (appState?.user?.username) {
            const method = 'member'
            const attributes =['email', 'phone_number', 'name', 'username', 'dob', 'currency', 'gender', 'line_id']
            query({
                method,
                params: [
                    { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.username },
                ],
                attributes

            }).then(({ data }) => {
                setUserInfo(data[method])
            }).catch((error) => {
                // console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        }


    }, [appState?.user?.username, query, refreshBrithday, refreshGender, appDispatch, navigate]);

    useEffect(() => {
        if (selectedDob !== '') {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }

    }, [selectedDob]);

    useEffect(() => {
        if (selectedGender !== '') {
            setDisabledGenderSubmit(false)
        } else {
            setDisabledGenderSubmit(true)
        }

    }, [selectedGender]);

    const onSubmit = () => {
        setLoading(true)
        const values = getValues();
        const method = 'changeDobByMember'
        let params = [
            { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
            { code: 'dob', graphqlType: 'Date', required: true, value: dayjs(selectedDob).format('YYYY-MM-DD') },
        ]

        mutation([{
            method,
            params,
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                setDobEdit(false)
                setRefreshGender(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }

            })


    };

    const onGenderSubmit = () => {
        setLoading(true)

        const method = 'updateGenderByMember'
        let params = [
            { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
            { code: 'gender', graphqlType: 'String', required: true, value: selectedGender },
        ]

        mutation([{
            method,
            params,
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                setGenderEdit(false)
                setRefreshBrithday(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }

            })
    }


    return (
        <>
            <div className="userInfo_body">
                {isDesktopOrLaptop ?
                    <div className="font_h2 color_primary wallet_container_title">{t('user_info')}</div> : (
                        showUserInfo ? (<Title id={"userInfo_title"} title={'user_info'} desktopToggle={false} mobileToggle={true} />) :
                            (<section className={`section pageTitle font_h2 color_primary mobile d-lg-none`} id={``}>
                                <>
                                    <Container className={` title_container`}>
                                        <Link onClick={() => setShowUserInfo(true)}><BiArrowBack /></Link>
                                        <div> {t('realName_verification')}
                                        </div>
                                    </Container>
                                </>
                            </section>)
                    )
                }
                <div className="wallet_container_wrap">
                    <Row>
                        {(isDesktopOrLaptop || (!isDesktopOrLaptop && showUserInfo)) && (<Col lg={6}>
                            <Card className="myAccount_userInfo_profile">
                                <div className="subTitle">{t('personal_information')}</div>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item className="">
                                        <label>{t('username')}</label>
                                        <p>{userInfo?.username}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="">
                                        <label>{t('dob')}</label>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Col lg={9} md={10} xs={10}><Form.Control type="text" placeholder={"YYYY-MM-DD"} value={userInfo?.dob ?? ""} disabled /></Col>
                                            {!userInfo?.dob && (<Col lg={3} className="d-flex align-items-center justify-content-center "><FaEdit className="font_h2 cursor-pointer" onClick={() => setDobEdit(true)} /></Col>)}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="">
                                        <label>{t('email')}</label>
                                        <p>{userInfo?.email}</p>
                                    </ListGroup.Item>
                                    
                                        <> <ListGroup.Item className="">
                                            <label>{t('gender')}</label>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Col lg={9} md={10} xs={10}><Form.Control type="text" placeholder={t('gender')} value={userInfo?.gender ?? ""} disabled /></Col>
                                                {!userInfo?.gender && (<Col lg={3} className="d-flex align-items-center justify-content-center "><FaEdit className="font_h2 cursor-pointer" onClick={() => setGenderEdit(true)} /></Col>)}
                                            </div>
                                        </ListGroup.Item>
                                            <ListGroup.Item className="">
                                                <label>{t('Line ID')}</label>
                                                <p>{userInfo?.line_id}</p>
                                            </ListGroup.Item>

                                            {!isDesktopOrLaptop && (<ListGroup.Item className="">
                                                <label>{t('realName_verification')}</label>
                                                <p><button className="font_button color_button verifyNow_button" onClick={() => setShowUserInfo(false)}>{t('verify_now')}</button></p>
                                            </ListGroup.Item>)}</>
                                    
                                </ListGroup>
                            </Card>
                            <Card className="myAccount_userInfo_profile">
                                <div className="subTitle">{t('contact_information')}</div>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <label>{t('phone_number')}</label>
                                        <p>{userInfo?.phone_number && (userInfo?.phone_number.slice(-4).padStart(userInfo?.phone_number.length, '*'))}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <label>{t('full_name')}</label>
                                        <p>{userInfo?.name}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <label>{t('currency')}</label>
                                        <p>{userInfo?.currency}</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>

                            {/* <Card className="myAccount_userInfo_profile">
                                <div className="subTitle">{t('banking_information')}</div>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <div className="userInfo_bankDetails_container">
                                            <div className="userInfo_bankDetails_card">
                                                <div className="userInfo_bankDetails_bankLogo">
                                                    <img src={`${GLOBAL_BUCKET}/bank/MYR/MBBEMYKL.png`} alt={'bank icon'} />
                                                </div>
                                                <div className="userInfo_bankDetails_bankProfile">
                                                    <div>34732843224</div>
                                                    <div>hihihi</div>
                                                </div>
                                                <div className="userInfo_bankDetails_icon">
                                                    <FaRegCheckCircle />
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroup.Item>

                                </ListGroup>
                            </Card> */}
                        </Col>)}

                       
                            <>
                                {isDesktopOrLaptop && <Col lg={12}>
                                    <Card className="myAccount_userInfo_profile">
                                        <RealNameVerification />
                                    </Card>
                                </Col>}


                                {(!isDesktopOrLaptop && !showUserInfo) && <RealNameVerification />}
                            </>
                       
                    </Row>
                    {/* :
                        <Container>
                            <Card className="myAccount_userInfo_profile">
                                <div className="myAccount_userInfo_profile_row">
                                    <div className="d-flex align-items-center">
                                        <img src={`${TENANT_BUCKET}/icon/userInfo_username.png`} alt={'userInfo username'} />
                                        <label>{t('username')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p>{userInfo?.username}</p>
                                    </div>
                                </div>
                                <div className="myAccount_userInfo_profile_row">
                                    <div className="d-flex align-items-center">
                                        <img src={`${TENANT_BUCKET}/icon/userInfo_fullname.png`} alt={'userInfo full name'} />
                                        <label>{t('full_name')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p>{userInfo?.name}</p>
                                    </div>
                                </div>
                                <div className="myAccount_userInfo_profile_row">
                                    <div className="d-flex align-items-center">
                                        <img src={`${TENANT_BUCKET}/icon/userInfo_phoneNumber.png`} alt={'userInfo contact number'} />
                                        <label>{t('phone_number')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p>{userInfo?.phone_number}</p>
                                    </div>
                                </div>
                                <div className="myAccount_userInfo_profile_row">
                                    <div className="d-flex align-items-center w-50">
                                        <img src={`${TENANT_BUCKET}/icon/userInfo_birthday.png`} alt={'userInfo dob'} />
                                        <label>{t('dob')}</label>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end w-50">
                                        <Col lg={10}><Form.Control type="text" placeholder={"dd/mm/yy"} disabled /></Col>
                                        <Col lg={2} className="d-flex align-items-center justify-content-center ms-2"><FaEdit className="font_h2" onClick={() => setDobEdit(true)} /></Col>
                                    </div>
                                </div>
                                <div className="myAccount_userInfo_profile_row">
                                    <div className="d-flex align-items-center">
                                        <img src={`${TENANT_BUCKET}/icon/userInfo_email.png`} alt={'userInfo email'} />
                                        <label>{t('email')}</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p>{userInfo?.email}</p>
                                    </div>
                                </div>
                            </Card>
                        </Container> */}

                </div>

                <Modal show={dobEdit} onHide={() => setDobEdit(false)} backdrop="static" centered id="dobEditModal">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('dob')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="form_field" >
                                <Form.Label>{t('date')}<div className="form_required">*</div></Form.Label>
                                <div className="form_field_input">
                                    <DatePicker
                                        selected={selectedDob}
                                        onChange={(date) => onChangeDob(date)}
                                        dateFormat="yyyy-MM-dd"
                                        className="font_h5 input_dob form-control"
                                        placeholderText={'YYYY-MM-DD'}
                                        aria-label="input_dob"
                                    />
                                </div>
                            </Form.Group>
                            <input className="font_button color_button wallet_btnSubmit" type='submit' value={t('submit')} disabled={disabledSubmit} />
                            <div className="reminder_container">
                                <div className="reminder_title">{t('reminder')}</div>
                                <div className="reminder_content">
                                    {t(`dobEdit_reminder`)}
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={genderEdit} onHide={() => setGenderEdit(false)} backdrop="static" centered id="genderEditModal">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('gender')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onGenderSubmit)}>
                            <Form.Group className="form_field" >
                                <Form.Label>{t('gender')}<div className="form_required">*</div></Form.Label>
                                <div className="form_field_input">
                                    <Form.Select aria-label="gender" className="input_gender" value={selectedGender} onChange={(evt) => { onChangeGender(evt.target.value) }}>
                                        <option hidden></option>
                                        <option value={'MALE'}>{t('Male')}</option>
                                        <option value={'FEMALE'}>{t('Female')}</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>
                            <input className="font_button color_button wallet_btnSubmit" type='submit' value={t('submit')} disabled={disabledGenderSubmit} />
                            {/* <div className="reminder_container">
                                <div className="reminder_title">{t('reminder')}</div>
                                <div className="reminder_content">
                                    {t(`genderEdit_reminder`)}
                                </div>
                            </div> */}
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            {isLoading && (<Loading />)}
        </>
    );

};






export default UserInfo;
