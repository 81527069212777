
import React, { useEffect, useState, useReducer, useRef } from "react";
import { Row, Col, Form, Modal, Accordion, OverlayTrigger, Popover } from "react-bootstrap";
import { FaExchangeAlt, FaChevronDown } from "react-icons/fa";

import { BiSortAlt2 } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useMiddletier } from "../common/middletier";
import Loading from '../components/Loading';
// import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
// import { FaInfoCircle } from "react-icons/fa";
import { TENANT_BUCKET, GAME_MENU_DATA } from '../common/constants';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'
import { useNavigate } from "react-router-dom"
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './ProviderSelector.css';


const ProviderSelector = ({ section, onSelect, data, currentProvider, otherProvider }) => {
    const appState = useStoreState()
    const { t, i18n } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const { queries, mutation, query } = useMiddletier()
    const appDispatch = useStoreDispatch()

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
      })

    let providerData = []
    providerData = data
  

    const formatAmount = (amount, decimalPoint) => {
        const factor = Math.pow(10, decimalPoint);
        const truncatedAmount = Math.floor(amount * factor) / factor;
        const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
            minimumFractionDigits: decimalPoint,
            maximumFractionDigits: decimalPoint,
        });
        return formattedAmount;
    }

    const onClickRefreshBalance = () => {
        if (appState.loggedIn) {
            setLoading(true)
            const method = 'member'

            query({
                method,
                params: [
                    { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                ],
                attributes: ['wallet_amount']

            }).then(({ data }) => {
                setLoading(false)
                appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data[method].wallet_amount })


            }).catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }

            })
        }
    }

    const onClickProvider = (provider)=>{
        onSelect(provider)
    }

    return (<>
        <div className={`game_wallet_dropdown providerSection_${section}`}>
            {((section === 'TRANSFER' && otherProvider !== 'MAIN_WALLET')||section === 'DEPOSIT') && (<div className="color_button main_wallet" onClick={() => onClickProvider('MAIN_WALLET')}>
                <div>{t('main_wallet')}</div>
                <div className="gameWallet_amount">{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</div>
                <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={
                        <Popover>
                            <Popover.Body className="icon_name">
                                {t(`refresh_balance`)}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <img src={`${TENANT_BUCKET}/icon/refresh_wallet_icon.png`} alt="" onClick={(e) =>{ e.stopPropagation(); onClickRefreshBalance()}} />
                </OverlayTrigger>
            </div>)}

            <Accordion className='gameWallet_accordion' >
                <SimpleBar style={{ maxHeight: isDesktopOrLaptop?'20rem':'30rem' }}>
                    {providerData.map(item => {
                        const totalBalance = item.options.reduce((sum, option) => sum + (parseFloat(option.balance) || 0), 0);
                        return (
                            <Accordion.Item eventKey={`#${item.code}`} id={item.code} key={item.code}>
                                <Accordion.Header>
                                    <div>{t(item.label)}</div>
                                    <div className="gameWallet_amount">{formatAmount(totalBalance, appState.currencyFormat.decimal_point)}</div>
                                    <div className="gameWallet_accordion_icon"><FaChevronDown /></div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="game_wallet_dropdown_body">
                                        <table>
                                            <thead>
                                            </thead>
                                            <tbody>
                                                {item.options?.filter(item => item.code !== otherProvider).map(option => (
                                                    <tr key={option.code} onClick={() => onClickProvider(option.code)}>
                                                        <td>{option.name}</td>
                                                        <td className="gameWallet_amount">{formatAmount(option.balance ?? 0, appState.currencyFormat.decimal_point)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </SimpleBar>
            </Accordion>

        </div>
        {isLoading && (<Loading />)}
    </>)
}


export default ProviderSelector;
