


import SECTION_1676449885066 from
'../dynamic_section/dynamic_2/template_01';






import Promotion from '../sections/promotion/template_01';






import SECTION_PROMOCONTAINER_1 from
'../dynamic_section/dynamic_1/template_01';





import SECTION_PROMOCONTAINER_2 from
'../dynamic_section/dynamic_1/template_01';





import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const PromotionPage = ()=>{
  
return (
  <div id="promotionPage" class="page ">
  
  

  
  < SECTION_1676449885066 desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `promo_title` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  
  

  
  <Promotion desktopTitleToggle={ false }
    mobileTitleToggle={ true } />
  
  
  
  

  
  < SECTION_PROMOCONTAINER_1 desktopToggle={ true }
    mobileToggle={ false } label={ `PromoContainer` } id={ `Section_promoContainer` }
    imageSize={`1x3`} contentPosition={`right`}
    actionPosition={`bottom`} rowDesktop={ 1 }
    rowMobile={ 1 } numberOfRow={ -1 }
    dataType={`promotion`} noPadding={ false } filter={``}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={``} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ true }
    folderImage={``} noLink={ true }/>
  
  
  
  

  
  < SECTION_PROMOCONTAINER_2 desktopToggle={ false }
    mobileToggle={ true } label={ `PromoContainer` } id={ `Section_promoContainer` }
    imageSize={`1x3`} contentPosition={`bottom`}
    actionPosition={`bottom`} rowDesktop={ 1 }
    rowMobile={ 1 } numberOfRow={ -1 }
    dataType={`promotion`} noPadding={ false } filter={``}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={``} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ true }
    folderImage={``} noLink={ true }/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  

  
</div>
)
}

export default PromotionPage;