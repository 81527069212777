import React, { useEffect, useState } from "react";
import { TENANT_BUCKET, HEADER_MENU_DATA, TENANT_NAME } from '../../common/constants'
import { useTranslation } from 'react-i18next';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Grid } from "swiper";
import { useMediaQuery } from 'react-responsive';


import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./template_01.css";

const Dynamic2 = (props) => {
  const { desktopToggle, mobileToggle, label, id, imageType, supportLanguage, linkTo, titleToggle, addContainer, numberOfImage, titleIconToggle, titlePosition, bgSection, rowImageDesktop, rowImageMobile, pagination, linkedSliderToggle } = props;
  const { t, i18n } = useTranslation();
  const [activeSliderIndex, setActiveSliderIndex] = useState('');



  const getLastLanguage = localStorage.getItem('current_language');
  // const [setGetLanguage, getLanguage] = useState(getLastLanguage ?? i18n.resolvedLanguage)

  const sectionStyle = bgSection ? {
    backgroundImage: `url('https://storage.googleapis.com/${TENANT_NAME}-images.imbaweb.com/background/bg_${id}.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  } : {};

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const getLink = HEADER_MENU_DATA[linkTo] ?? ''
  // console.log('getLink:', getLink)

  const paginations = {
    clickable: true,
  };

  const handleSlideChange = (swiper) => {
    if (!linkedSliderToggle) {
      return;
    }
    else {
      const slideIndex = swiper.activeIndex;
      setActiveSliderIndex(slideIndex);
    }

  }

  // useEffect(() => {
  //   setGetLanguage(i18n.resolvedLanguage)
  // }, [i18n.resolvedLanguage,setGetLanguage])

  // console.log('image template2', `${TENANT_BUCKET}/section/${id}_${getLastLanguage ?? i18n.resolvedLanguage}.${imageType ?? 'png'}`)

  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section className={`section dynamic2 ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`} id={`${id}`} style={sectionStyle}>
          <div className={`${addContainer ? 'container' : ''}`}>
            {titleToggle ?
              <div className={`section_header`} style={{ justifyContent: titlePosition ? titlePosition : 'start' }}>
                <div className="font_h2 section_title">
                  {titleIconToggle && (<img src={`${TENANT_BUCKET}/icon/${id}_title_icon.png`} alt="title icon" />)}
                  {label && t(label)}</div>
              </div>
              : <></>}

            {numberOfImage > 1 ? <><Swiper
              slidesPerView={isDesktopOrLaptop ? (rowImageDesktop ?? 1) : (rowImageMobile ?? 1)}
              // centeredSlides={true}
              onSlideChange={handleSlideChange}
              autoplay={true}
              pagination={pagination ? paginations : false}
              navigation={linkedSliderToggle ? true : false}
              spaceBetween={20}
              slidesPerGroup={1}
              // modules={[Autoplay, Pagination, Navigation]}
              className="dynamic2_swiper section w-100"
              id="dynamic2_slider"
              // loop={true}
              breakpoints={{

                320: {
                  // width: 320,
                  slidesPerView: rowImageMobile ?? 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: rowImageMobile ?? 1,
                },
                1000: {
                  slidesPerView: rowImageDesktop ?? 1,
                  spaceBetween: 20
                },

              }}
            >
              {Array.from({ length: numberOfImage }).map((item, index) => {
                return (
                  <SwiperSlide key={`${id}_${index}`}>
                    {supportLanguage ?
                      <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}_${getLastLanguage ?? i18n.resolvedLanguage}_${(index + 1) >= 10 ? (index + 1) : `0${index + 1}`}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} /> :
                      <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}_${(index + 1) >= 10 ? (index + 1) : `0${index + 1}`}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} />}
                  </SwiperSlide>
                )
              })}

            </Swiper>
              {linkedSliderToggle && (<div className={`${addContainer ? 'container' : ''}`}>
                {supportLanguage ?
                  <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}_content_${getLastLanguage ?? i18n.resolvedLanguage}_${(activeSliderIndex + 1) >= 10 ? (activeSliderIndex + 1) : `0${activeSliderIndex + 1}`}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} /> :
                  <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}_content_${(activeSliderIndex + 1) >= 10 ? (activeSliderIndex + 1) : `0${activeSliderIndex + 1}`}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} />}
              </div>)}</>
              :
              <Link to={`${getLink !== '' ? getLink.url : '#'}`} style={{ cursor: getLink !== '' ? 'pointer' : 'default' }}>
                {supportLanguage ?
                  <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}_${getLastLanguage ?? i18n.resolvedLanguage}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} /> :
                  <ImageWithPlaceholder src={`${TENANT_BUCKET}/section/${id}.${imageType ?? 'png'}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`${label}`} />}
              </Link>}
          </div>
        </section >

      </>
    );
  }

}



export default Dynamic2;
