import React, { useState, useEffect, useReducer } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import { FaInfoCircle } from "react-icons/fa";
import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import { useNavigate } from "react-router-dom";

const WITHDRAW_ACTION = {
    SET_BANK_ACCOUNTS: 'SET_BANK_ACCOUNTS',
    SET_SELECTED_BANK_ACCOUNT: 'SET_SELECTED_BANK_ACCOUNT',
    SET_WITHDRAW_AMOUNT: 'SET_WITHDRAW_AMOUNT',
    SET_LIMIT_WITHDRAW_AMOUNT: 'SET_LIMIT_WITHDRAW_AMOUNT'
}

const initialWithdrawData = {
    bankAccounts: [],
    selectedBankAccount: { id: '', account_name: '', account_number: '', bank: '', bank_name: '' },
    withdrawAmount: 0,
    limitWithdrawAmount: { min_withdrawal: 0, max_withdrawal: 0, daily_max_withdrawal_count: 0 }
}

const withdrawReducer = (state, action) => {
    switch (action.type) {

        case WITHDRAW_ACTION.SET_BANK_ACCOUNTS: {
            const bankAccounts = [...action.payload]
            return { ...state, bankAccounts, selectedBankAccount: bankAccounts.length > 0 ? { ...bankAccounts[0] } : { id: '', account_name: '', account_number: '', bank: '', bank_name: '' } };
        }

        case WITHDRAW_ACTION.SET_SELECTED_BANK_ACCOUNT: {
            const selectedBankAccount = state.bankAccounts.find((bankAccounts) => bankAccounts.id === action.payload)
            return { ...state, selectedBankAccount };
        }

        case WITHDRAW_ACTION.SET_WITHDRAW_AMOUNT: {
            return { ...state, withdrawAmount: action.payload };
        }

        case WITHDRAW_ACTION.SET_LIMIT_WITHDRAW_AMOUNT: {
            return { ...state, limitWithdrawAmount: action.payload };
        }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const Withdraw = () => {
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const { mutation, queries } = useMiddletier();
    const { t } = useTranslation();
    const [withdrawState, withdrawDispatch] = useReducer(withdrawReducer, initialWithdrawData)
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })


    const onChangeBankAccount = (newValue) => {
        withdrawDispatch({ type: WITHDRAW_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: newValue })
    }

    const onChangeWithdrawAmount = (newValue) => {
        withdrawDispatch({ type: WITHDRAW_ACTION.SET_WITHDRAW_AMOUNT, payload: newValue })
    }

    const clearInput = () => {
        withdrawState.bankAccounts?.length > 0 && (withdrawDispatch({ type: WITHDRAW_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: withdrawState.bankAccounts[0]?.id }))
        withdrawDispatch({ type: WITHDRAW_ACTION.SET_WITHDRAW_AMOUNT, payload: 0 })
    }

    // WITHDRAW SUBMIT
    const handleSubmit = () => {
        setLoading(true)
        const method = 'withdrawMainWalletByMember'

        let params = [
            // { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
            // { code: 'transaction_type', graphqlType: 'String', required: true, value: 'WITHDRAW' },
            { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency ?? appState.currency },
            { code: 'amount', graphqlType: 'Float', required: true, value: -parseFloat(withdrawState.withdrawAmount) },
            { code: 'member_bank_account', graphqlType: 'String', required: false, value: withdrawState.selectedBankAccount?.id },
            { code: 'force_withdraw', graphqlType: 'Boolean', required: true, value: false },
        ]

        mutation([{
            method,
            params,
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'Withdraw Successful', typeAlert: 'success' }
                });
                clearInput()

            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
    };

    useEffect(() => {
        // const method = 'member_bank_accounts'
        setLoading(true)
        if (typeof appState.user?.username !== 'undefined') {
            queries([{
                method: 'member_bank_accounts',
                params: [
                    { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState?.user?.username, status: 'ACTIVE' } } },
                ],
                attributes: ['id', ['name', 'account_name'], ['_label', 'account_number'], ['bank_label', 'bank_name'], 'bank', 'status']

            }, {
                method: 'member_group',
                params: [
                    { code: 'id', graphqlType: 'String', required: true, value: appState?.user?.member_group ?? 'DEFAULT' },
                ],
                attributes: ['min_withdrawal', 'max_withdrawal', 'daily_max_withdrawal_count']

            }]).then(({ data }) => {
                setLoading(false)
                withdrawDispatch({ type: WITHDRAW_ACTION.SET_BANK_ACCOUNTS, payload: data['member_bank_accounts'] })
                withdrawDispatch({ type: WITHDRAW_ACTION.SET_LIMIT_WITHDRAW_AMOUNT, payload: data['member_group'] })

                if (data['member_bank_accounts'].length === 0) {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: 'No bank details found, please add bank details.', forwardUrl: '/wallet/banking_details' }
                    });

                }

            }).catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        }


    }, [appState.user, queries, navigate, appDispatch]);


    // CHECK FORM SUBMIT
    useEffect(() => {
        if (withdrawState.selectedBankAccount?.id !== '' && (withdrawState.withdrawAmount >= parseFloat(withdrawState.limitWithdrawAmount?.min_withdrawal ?? 0) && withdrawState.withdrawAmount <= parseFloat(withdrawState.limitWithdrawAmount?.max_withdrawal))) {
            setDisabledSubmit(false)
        }
        else {
            setDisabledSubmit(true)
        }

    }, [withdrawState.selectedBankAccount?.id, withdrawState.withdrawAmount, withdrawState.limitWithdrawAmount?.min_withdrawal, withdrawState.limitWithdrawAmount?.max_withdrawal])


    return (
        <>
            <div className="withdrawal_body">
                {isDesktopOrLaptop && <div className="font_h2 color_primary wallet_container_title">{t('withdraw')}</div>}
                <div className="wallet_container_wrap">
                    <Row>
                        <Col lg={7} >
                            <Form className="withdraw_form">
                                <Form.Group className="form_input_wrap" >
                                    <div className="input_wrap">
                                        <Form.Label>{t('account_name')}
                                            <span className="form_required_input">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('account_name')}
                                            className=" input_accountName"
                                            aria-label="accountName"
                                            value={withdrawState.selectedBankAccount?.account_name ?? ''}
                                            disabled
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('withdrawal_bank')}<span className="form_required_input">* {t('required_select')}</span></Form.Label>
                                        
                                        <Form.Select aria-label="withdrawalBank" className="input_withdrawalBank" value={withdrawState.selectedBankAccount?.id} onChange={(evt) => { onChangeBankAccount(evt.target.value) }}>
                                            {withdrawState.bankAccounts?.map(function (item, index) {
                                                return (
                                                    <option value={item.id} key={item.id}>{item.bank_name}-{item.account_number}</option>
                                                )
                                            })}

                                        </Form.Select>
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('bank_name')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('bank_name')}
                                            className=" input_bankName"
                                            aria-label="bankName"
                                            value={withdrawState.selectedBankAccount?.bank_name ?? ''}
                                            disabled
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('account_number')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('account_number')}
                                            className=" input_accountNo"
                                            aria-label="accountNo"
                                            value={withdrawState.selectedBankAccount?.account_number ?? ''}
                                            disabled
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('withdrawal_amount')}
                                            <span className="form_required_input">* {t('required_fill_in')}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('withdrawal_amount_placeholder', { "min": parseFloat(withdrawState.limitWithdrawAmount?.min_withdrawal ?? 0).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(withdrawState.limitWithdrawAmount?.max_withdrawal ?? 0).toLocaleString(appState.currencyFormat?.locale) })}
                                            className=" input_withdrawalAmount"
                                            aria-label="withdrawalAmount"
                                            min={0}
                                            value={withdrawState.withdrawAmount === 0 ? '' : withdrawState.withdrawAmount}

                                            onChange={(evt) => { onChangeWithdrawAmount(evt.target.value) }}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onInput={(e) => {
                                                // let value = appState.currencyFormat.decimal_point === 0
                                                //   ? e.target.value.replace(/[^0-9]/g, '')
                                                //   : e.target.value.replace(/[^0-9.]/g, '');

                                                // const decimal_part = value.split(".")[1];
                                                // if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                                                //   value = value.slice(0, -1);
                                                // }
                                                // e.target.value = value;
                                                let value = appState.currencyFormat.decimal_point === 0
                                                    ? e.target.value.replace(/[^0-9]/g, '')
                                                    : e.target.value.replace(/[^0-9.]/g, '');

                                                if (value.split('.').length - 1 > 1) {
                                                    value = value.substring(0, value.lastIndexOf('.'));
                                                }

                                                const decimal_part = value.split(".")[1];
                                                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                                                    value = value.slice(0, -1);
                                                }
                                                e.target.value = value;
                                                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />
                                        <div className="info_input_wrap">
                                            <FaInfoCircle />
                                            <span>{t('withdrawal_amount_placeholder', { "min": parseFloat(withdrawState.limitWithdrawAmount?.min_withdrawal ?? 0).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(withdrawState.limitWithdrawAmount?.max_withdrawal ?? 0).toLocaleString(appState.currencyFormat?.locale) })}</span>
                                            {withdrawState.limitWithdrawAmount?.daily_max_withdrawal_count > 0 && (<span> {t('withdrawal_daily_max_count')} : {withdrawState.limitWithdrawAmount?.daily_max_withdrawal_count}</span>)}
                                        </div>
                                    </div>

                                </Form.Group>
                                <input className="font_button color_button wallet_btnSubmit" type='button' value={t('submit')} disabled={disabledSubmit} onClick={handleSubmit} />
                            </Form>
                        </Col>
                        <Col lg={5} >
                            <div className="wallet_reminder_container">
                                <div className="wallet_reminder_title">{t('reminder')}</div>
                                <div className="wallet_reminder_content">
                                    {t('withdrawal_reminder')}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {isLoading && (<Loading />)}
        </>
    );




};






export default Withdraw;
